window.createQueryParams = (params) => {
  let query = "";

  for (let key in params)
    query += encodeURIComponent(key)
      + '='
      + encodeURIComponent(params[key])
      + '&';

  return query.slice(0, -1);
};
